export const environment = {
  production: true,
  env_name: 'prod',
  accountId: '586224314720',


  castlabs_idp_issuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_MMywSi1v4',
  castlabs_idp_app_client_id: 'uicu359vtkfgq881c4bci7s7a',
  castlabs_idp_logout_url: 'https://login.castlabs.com/logout?client_id={{client_id}}&logout_uri=\' + window.location.origin + \'/',


  portal_idp_issuer: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_ABq6OfhiY',
  portal_idp_app_client_id: '302ul788set85c2evl6s7jacv',
  portal_idp_logout_url: 'https://portal-login.content.castlabs.com/logout?client_id={{client_id}}&logout_uri=' + window.location.origin + '/',

  aerq_idp_issuer: 'https://usermanager.aerena.com/auth/realms/controltower',
  aerq_idp_app_client_id: 'castlabs-red-portal',

  touch_idp_issuer: 'https://id.mediacenter.aero',
  touch_idp_app_client_id: '',
  touch_idp_app_client_secret: '',

  above_idp_issuer: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_Pk3lI2gyt',
  above_idp_app_client_id: '2joit9qv0serdel87j8fk0gv84',

  apiurl: 'https://portal.content.castlabs.com/current',
  uploaderurl: 'https://up.content.castlabs.com',

  VTK: 'https://vtk.castlabs.com',
  Portal: 'https://portal.content.castlabs.com',

  region: 'us-east-1',
  graphql: 'https://repository.content.castlabs.com/graphql',
  workflow_graphql: 'https://workflow.content.castlabs.com/graphql',

  sentry_dsn: 'https://6159d540586d4d698519d4a62a2fd6f2@o457257.ingest.sentry.io/5509849',
  title: 'castLabs Content Portal',

  delta_dfp_destination: 'delta_dfp_aspera',
};
