<div class="container cl-form cl-spacing-small" [formGroup]="poFormGroup">
  <input *ngIf="airlines.length == 1" type="hidden" formControlName="airline" [value]="airlines[0].iata_code">
  <div class="row">
    <div *ngIf="airlines.length > 1" class="col-4">
      <label for="airline" class="form-label">Airline<span class="required">*</span></label>
      <select id="airline" class="form-select" formControlName="airline">
        <option *ngFor="let airline of airlines" [value]="airline.iata_code"> {{ airline.airline_name }}
          ({{ airline.iata_code }})
        </option>
      </select>
      <p *ngIf="poFormGroup.controls.airline.errors?.required" class="invalid-feedback">This field is required.</p>
      <p *ngIf="!poFormGroup.controls.airline.errors" class="valid-feedback">Selects the airline on castLabs' side.</p>
    </div>


    <div [class.col-4]="airlines.length > 1" [class.col-8]="airlines.length === 1">
      <label for="po_number" class="form-label">PO Name<span class="required">*</span></label>
      <input id="po_number" type="text" formControlName="po_number" pattern="[A-Za-z0-9_\-.]+">
      <p *ngIf="poFormGroup.controls.po_number.errors?.required" class="invalid-feedback">This field is
        required.</p>
      <p *ngIf="poFormGroup.controls.po_number.errors?.pattern" class="invalid-feedback">Only letters, numbers and '_', '-' or '.' are allowed</p>
      <p *ngIf="!poFormGroup.controls.po_number.errors" class="valid-feedback">The encoding PO number for later
        reference</p>
    </div>
    <div class="col-4">
      <label for="email_notification" class="form-label">Email Notification</label>
      <input id="email_notification" type="text" formControlName="email_notification">
      <p *ngIf="poFormGroup.controls.email_notification.errors?.required" class="invalid-feedback">This field is
        required.</p>
      <p *ngIf="poFormGroup.controls.email_notification.errors?.email" class="invalid-feedback">Please insert a valid
        email.</p>
      <p *ngIf="!poFormGroup.controls.email_notification.errors" class="valid-feedback">A notification email will be
        sent to this address</p>
    </div>
    <div class="col-4 ">
      <label for="ife" class="form-label">IFE System<span class="required">*</span></label>
      <select id="ife" class="form-select" formControlName="ife" [compareWith]="compare_item_id">
        <option *ngFor="let sys of availableSystems" [value]="sys.item_id">{{ sys.item_text }}</option>
      </select>
      <p *ngIf="poFormGroup.controls.ife.errors?.required" class="invalid-feedback">This field is required.</p>
      <p *ngIf="!poFormGroup.controls.ife.errors" class="valid-feedback">Select the IFE system.</p>

    </div>

    <div class="col-4 ">
      <label for="ife_format" class="form-label">IFE Format<span class="required">*</span></label>
      <select id="ife_format" class="form-select" formControlName="ife_format" [compareWith]="compare_item_id">
        <option *ngFor="let sys of ifeFormats" [value]="sys.item_id">{{ sys.item_text }}</option>
      </select>
      <p *ngIf="poFormGroup.controls.ife_format.errors?.required" class="invalid-feedback">This field is required.</p>
      <p *ngIf="!poFormGroup.controls.ife_format.errors" class="valid-feedback">Select the desired format.</p>
    </div>

    <div class="col-4 ">
      <label for="po_destination" class="form-label">Delivery Destination<span class="required">*</span></label>
      <select id="po_destination" class="form-select" formControlName="po_destination"
              [compareWith]="compare_destination_id">
        <option *ngFor="let sys of poDestinations" [value]="sys.destination_id">{{ sys.display_name }}</option>
      </select>
      <p *ngIf="poFormGroup.controls.po_destination.errors?.required" class="invalid-feedback">This field is
        required.</p>
      <p *ngIf="!poFormGroup.controls.po_destination.errors" class="valid-feedback">Select the desired destination.</p>
    </div>

    <div class="col-4 ">
      <label for="auto_publish" class="form-label">Publication</label>
      <select id="auto_publish" class="form-select" formControlName="auto_publish">
        <option [value]="true"> Automatic Publication
        </option>
        <option [value]="false"> Manual Publication
        </option>
      </select>
      <p class="valid-feedback">Manual Publication provides a final QA step before distributing the content, whereas Automatic Publication releases the content immediately upon readiness.</p>
    </div>
    <div class="col-4"
         [style.display]="this.poFormGroup.get('po_destination')?.value === 'west_entertainment'?'block':'none'">
      <label for="westent_cycle_name" class="form-label">WestEnt Cycle Name</label>
      <input id="westent_cycle_name" type="text" formControlName="westent_cycle_name">
      <p *ngIf="poFormGroup.controls.westent_cycle_name.errors?.required" class="invalid-feedback">This field is
        required.</p>
      <p *ngIf="!poFormGroup.controls.westent_cycle_name.errors" class="valid-feedback">Required for WestEnt to
        determine correct delivery location</p>
    </div>
  </div>

  <ng-container formArrayName="poItems">
    <ng-container [formGroup]="poItems.controls[idx]" [formGroupName]="idx"
                  *ngFor="let item of poItems.controls ; let idx = index; trackBy: poItemTrackBy">
      <div class="row cl-spacing-small">
        <div class="col-12">
          <h3 [class.warn]="poItems.controls[idx].invalid">{{ poItems.controls[idx].value['basics']['po_item_id'] }}
            <button class="cl-a-quickaction" (click)="poService.removePOItem(poItems.controls[idx].value['id'])">
              Remove
            </button>
          </h3>
        </div>

      </div>
      <input id="idinput" type="hidden" formControlName="id">
      <app-basic-poitem-info
        formControlName="basics"
        [organization]="organization"
        [id]="poItems.controls[idx].value['id']">
      </app-basic-poitem-info>
      <app-track-selection
        formControlName="tracks">
      </app-track-selection>
      <app-above-default-specific formControlName="above_specific"
                                  [ife_format]="ifeFormat"></app-above-default-specific>
      <app-above-default-format-specific formControlName="format_specific_data"
                                         [ifeFormat]="ifeFormat"></app-above-default-format-specific>
      <div class="row">
        <div class="col-12">
          <hr class="cl-spacing-small">
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="row">
    <div class="col-12">

      <div class=" cl-spacing-box text-end">
        <button class="btn cl-btn-primary" (click)="submit_po()"><span>Start Encode</span><i
          class="fas fa-angle-right"></i></button>
      </div>
    </div>
  </div>

</div>
